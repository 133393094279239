export const System = {
  ColorStreams: 'ColorStreams',
  Environmental: 'Environmental',
  Title: 'Title'
}

export const Canvas = {
  InstagramStory: {
    name: 'Instagram Story (9x16)',
    id: 'instagram-story',
    aspectRatio: 16/9,
    defaultWidth: 400,
    defaultFontSize: 40,
    logoMultiplier: 2.5
  },
  InstagramPost: {
    name: 'Instagram Post (4x5)',
    id: 'instagram-post',
    aspectRatio: 5/4,
    defaultWidth: 400,
    defaultFontSize: 38,
    logoMultiplier: 2.5
  },
  Landscape: {
    name: 'Landscape (16x9)',
    id: 'landscape',
    aspectRatio: 9/16,
    defaultWidth: 800,
    defaultFontSize: 42,
    logoMultiplier: 2.5
  },
  UltraWide: {
    name: 'Ultra Wide (3x1)',
    id: 'ultra-wide',
    aspectRatio: 1/3,
    defaultWidth: 1200,
    defaultFontSize: 48,
    logoMultiplier: 2.5
  }
};

export const FontSize = [32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52];

export const Type = {
  Static: 'Static',
  Motion: 'Motion'
};

export const NumberOfLines = {
  One: 1,
  Two: 2
}

export const Backgrounds = [
{
  name: 'Blue / Green',
  id: 'ambient-bright-blue-green'
},
{
  name: 'Blue / Pink',
  id: 'ambient-bright-blue-pink'
},
{
  name: 'Green / Yellow',
  id: 'ambient-bright-green-yellow'
},
{
  name: 'Orange / Pink',
  id: 'ambient-bright-orange-pink'
},
{
  name: 'Purple / Blue',
  id: 'ambient-bright-purple-blue'
},
{
  name: 'Purple / Orange',
  id: 'ambient-bright-purple-orange'
},
{
  name: 'Purple / Red',
  id: 'ambient-bright-purple-red'
},
{
  name: 'Yellow / Orange',
  id: 'ambient-bright-yellow-orange'
},
{
  name: 'Red',
  id: 'ambient-bright-red'
},
// {
//   name: 'Ambient Dark Green Blue',
//   id: 'ambient-dark-green-blue'
// },
// {
//   name: 'Ambient Dark Green Yellow',
//   id: 'ambient-dark-green-yellow'
// },
// {
//   name: 'Ambient Dark Orange Yellow',
//   id: 'ambient-dark-orange-yellow'
// },
// {
//   name: 'Ambient Dark Pink Blue',
//   id: 'ambient-dark-pink-blue'
// },
// {
//   name: 'Ambient Dark Pink Orange',
//   id: 'ambient-dark-pink-orange'
// },
// {
//   name: 'Ambient Dark Purple Blue',
//   id: 'ambient-dark-purple-blue'
// },
// {
//   name: 'Ambient Dark Purple Orange',
//   id: 'ambient-dark-purple-orange'
// },
// {
//   name: 'Ambient Dark Purple Red',
//   id: 'ambient-dark-purple-red'
// },
// {
//   name: 'Ambient Dark Red',
//   id: 'ambient-dark-red'
// },
{
  name: 'Blue / Green',
  id: 'radiant-bright-blue-green'
},
{
  name: 'Blue / Pink',
  id: 'radiant-bright-blue-pink'
},
{
  name: 'Green / Yellow',
  id: 'radiant-bright-green-yellow'
},
{
  name: 'Orange / Pink',
  id: 'radiant-bright-orange-pink'
},
{
  name: 'Purple / Blue',
  id: 'radiant-bright-purple-blue'
},
{
  name: 'Purple / Orange',
  id: 'radiant-bright-purple-orange'
},
{
  name: 'Purple / Red',
  id: 'radiant-bright-purple-red'
},
{
  name: 'Yellow / Orange',
  id: 'radiant-bright-yellow-orange'
},
{
  name: 'Red',
  id: 'radiant-bright-red'
},
// {
//   name: 'Radiant Dark Green Blue',
//   id: 'radiant-dark-green-blue'
// },
// {
//   name: 'Radiant Dark Green Yellow',
//   id: 'radiant-dark-green-yellow'
// },
// {
//   name: 'Radiant Dark Orange Yellow',
//   id: 'radiant-dark-orange-yellow'
// },
// {
//   name: 'Radiant Dark Pink Blue',
//   id: 'radiant-dark-pink-blue'
// },
// {
//   name: 'Radiant Dark Pink Orange',
//   id: 'radiant-dark-pink-orange'
// },
// {
//   name: 'Radiant Dark Purple Blue',
//   id: 'radiant-dark-purple-blue'
// },
// {
//   name: 'Radiant Dark Purple Orange',
//   id: 'radiant-dark-purple-orange'
// },
// {
//   name: 'Radiant Dark Purple Red',
//   id: 'radiant-dark-purple-red'
// },
// {
//   name: 'Radiant Dark Red',
//   id: 'radiant-dark-red'
// },
// {
//   name: 'Soft Bright Blue Green',
//   id: 'soft-bright-blue-green'
// },
// {
//   name: 'Soft Bright Purple Blue',
//   id: 'soft-bright-purple-blue'
// },
// {
//   name: 'Soft Dark Blue Green',
//   id: 'soft-dark-blue-green'
// },
// {
//   name: 'Soft Dark Purple Blue',
//   id: 'soft-dark-purple-blue'
// },
]

export const StreamType = {
  Ambient: 'ambient',
  Radiant: 'radiant',
}

export const Hearts = [
  '♥️',
  '❤️',
  '🧡',
  '💛',
  '💚',
  '💙',
  '💜',
  '💖',
  '🤎',
  '🖤'
];