import React from 'react';
import _ from 'lodash';
import { View, Text, StyleSheet } from 'react-native-web';

// Constants
import { Canvas, Hearts } from '../constants';
import { randomizeStream } from '../utils';

import Sidebar from '../components/Sidebar';
import Moodular from '../components/Moodular';

const coloredHeart = Hearts[_.random(0, Hearts.length - 1)];
const [defaultStreamType, defaultStreamColor] = randomizeStream();

const defaultCanvasId = Object.keys(Canvas)[0];
export default function HomeScreen({ navigation, route }) {
  const [firstLineOfText, setFirstLineOfText] = React.useState();
  const [secondLineOfText, setSecondLineOfText] = React.useState();
  const [numberOfLines, setNumberOfLines] = React.useState(2);
  const [canvasId, setCanvasId] = React.useState(defaultCanvasId);
  const [streamType, setStreamType] = React.useState(defaultStreamType);
  const [streamColor, setStreamColor] = React.useState(defaultStreamColor);
  const [streamInverted, setStreamInverted] = React.useState(false);
  const [fontSize, setFontSize] = React.useState(Canvas[defaultCanvasId].defaultFontSize);

  const state = {
    firstLineOfText,
    secondLineOfText,
    numberOfLines,
    canvasId,
    streamType,
    streamColor,
    streamInverted,
    fontSize
  }

  const actions = {
    setFirstLineOfText,
    setSecondLineOfText,
    setNumberOfLines,
    setCanvasId,
    setStreamColor,
    setStreamType,
    setStreamInverted,
    setFontSize
  }

  return (
    <View style={styles.screen}>
      <View style={styles.header}>
        <View>
          <Text style={styles.headerText}>Möodular Editor</Text>
          <Text style={{
            fontFamily: 'Netflix Sans',
            fontSize: 12,
            marginTop: -4,
            color: 'white'
          }}>a hackday project ✨</Text>
        </View>
        <a href="https://go.netflix.com/moodular" rel="noreferrer" target="_blank">
          <Text style={styles.headerSecondary}>What's a Möodular?</Text>
        </a>
      </View>
      <View style={styles.main}>
        <View style={styles.workArea}>
          <Text style={{
            color: '#7a7a7a',
            fontFamily: 'Netflix Sans',
            marginBottom: 10,
          }}>{Canvas[canvasId].name}</Text>
          <Moodular
            canvasId={canvasId}
            numberOfLines={numberOfLines}
            firstLineOfText={firstLineOfText}
            secondLineOfText={secondLineOfText}
            streamColor={streamColor}
            streamInverted={streamInverted}
            fontSize={fontSize}
          />
        </View>
        <View style={styles.sidebar}>
          <Sidebar
            state={state}
            actions={actions}
          />
          <View style={styles.attributionContainer}>
            <Text style={styles.heartText}>{coloredHeart}</Text>
            <Text style={styles.madeWithLoveText}>
              Brought to you by <a target="_blank" rel="noreferrer" href="https://whitepages.prod.netflix.net/person/vhristova@netflix.com">venti</a> and <a target="_blank" rel="noreferrer" href="https://whitepages.prod.netflix.net/person/naoufal@netflix.com">naoufal</a></Text>
          </View>

        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    paddingHorizontal: 30,
    backgroundColor: '#2c2c2c',
    borderBottomWidth: 1,
    borderColor: '#000',
    zIndex: 1
  },
  headerText: {
    fontFamily: 'Netflix Sans',
    fontWeight: '300',
    fontSize: 30,
    color: 'white',
    letterSpacing: -0.5
  },

  headerSecondary: {
    fontFamily: 'Netflix Sans',
    fontSize: 16,
    color: 'white',
    textDecoration: 'underline'
  },
  attributionContainer: {
    // flex: 1,
    flexDirection: 'row',
    // borderWidth: 1,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderTopWidth: 1,
    borderColor: '#e5e5e5'
  },
  heartText: {
    fontFamily: 'Netflix Sans',
    fontSize: 13,
    lineHeight: '1.5',
    color: '#555'
  },
  madeWithLoveText: {
    fontFamily: 'Netflix Sans',
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 4,
    lineHeight: '1.5',
    color: '#555'
  },
  main: {
    flex: 1,
    flexDirection: 'row'
  },
  sidebar: {
    borderLeftWidth: 1,
    borderColor: '#e5e5e5',
    backgroundColor: 'white'
  },
  workArea: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e5e5e5'
  },

  // Editor
  sidebarSectionHeading: {
    marginBottom: 16,
    fontFamily: 'Netflix Sans',
    fontWeight: 'bold',
    fontSize: 16,
    color: '#333'
  },

  label: {
    marginBottom: 10,
    fontFamily: 'Netflix Sans',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#333'
  },

  input: {
    flex: 1,
    marginBottom: 20,
    padding: 10,
    paddingRight: 20,
    borderRadius: 2,
    borderWidth: 1,
    fontSize: 14,
  },

  // Moodular
  foreground: {
    position: 'relative',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Netflix Sans',
    fontWeight: '900',
    textAlign: 'center'
  },
  textClipping: {
    // backgroundSize: '100% 100%',
    // backgroundClip: "text",
    // color: "transparent",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent"
  }
})