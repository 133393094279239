import React from 'react';
import LinearGradient from 'react-native-web-linear-gradient';
import { View, Text, StyleSheet } from 'react-native-web';
import { ReactComponent as NetflixLogo } from '../assets/logo.svg';
import { Canvas } from '../constants';


const LOGO_ASPECT_RATIO = 276.742 / 1024;
export default function Moodular({
  canvasId,
  numberOfLines = 1,
  firstLineOfText = 'First line.',
  secondLineOfText = 'Second line.',
  streamColor,
  streamInverted,
  fontSize = Canvas[canvasId].defaultFontSize
}) {
  const canvas = Canvas[canvasId];
  const backgroundImage = require(`../assets/brand/background/${streamColor}.jpg`).default;
  const width = canvas.defaultWidth;
  const height = width * canvas.aspectRatio
  const paddingHorizontalSize = width / 10;
  const paddingVerticalSize = height / 10;

  const backgroundStyles = streamInverted
    ? { backgroundColor: 'black' }
    : { backgroundColor: 'white', backgroundImage: `url(${backgroundImage})`, backgroundSize: '100% 100%', backdropFilter: 'blur(10px)' }

  const foregroundStyles = streamInverted
    ? { backgroundImage: `url(${backgroundImage})`, backgroundSize: '100% 100%' }
    : { backgroundColor: 'black' };

  return (
    <View
      nativeID="moodular"
      style={[{
        overflow:'hidden',
        width: width,
        height: height,
        paddingHorizontal: width > height
          ? paddingVerticalSize
          : paddingHorizontalSize,
        paddingVertical: width > height
          ? paddingVerticalSize
          : paddingHorizontalSize,
      }, backgroundStyles]}
    >
      <Foreground
        foregroundStyles={foregroundStyles}
        paddingHorizontalSize={paddingHorizontalSize}
        paddingVerticalSize={paddingVerticalSize}
        isInverted={streamInverted}
        streamColor={streamColor}
      >
        <Copy
          streamColor={streamColor}
          streamInverted={streamInverted}
          firstLineOfText={firstLineOfText}
          secondLineOfText={secondLineOfText}
          numberOfLines={numberOfLines}
          fontSize={fontSize}
          paddingHorizontalSize={paddingHorizontalSize}
          width={width}
        />
        <NetflixLogo style={{
          position: 'absolute',
          bottom: 30,
          width: paddingHorizontalSize * canvas.logoMultiplier,
          height: (paddingVerticalSize * canvas.logoMultiplier) * LOGO_ASPECT_RATIO
        }}/>
      </Foreground>
    </View>
  )
}

function Foreground({ streamColor, isInverted, paddingHorizontalSize, paddingVerticalSize, foregroundStyles, children }) {
  if (isInverted) {
    return (
      <View style={[styles.foreground, foregroundStyles, {
        paddingHorizontal: paddingHorizontalSize,
        // paddingVertical: paddingVerticalSize * 3
      }]}>
        {children}
      </View>
    );
  }

  return (
    <LinearGradient
      colors={['rgba(0, 0, 0, 0.75)', '#000', '#000', '#000', 'rgba(0, 0, 0, 0.75)']}
      start={{x: 0, y: 0}} end={{x: 1, y: 0}}
      useAngle={true}
      angle={135}
      style={[styles.foreground, {
        paddingHorizontal: paddingHorizontalSize,
        // paddingVertical: paddingVerticalSize * 3,
      }]}
    >
      {children}
    </LinearGradient>
  )
}

function Copy({ width, streamColor, streamInverted, fontSize, numberOfLines, firstLineOfText, secondLineOfText, paddingHorizontalSize }) {
  const textBackgroundImage = require(`../assets/brand/background/blur/${streamColor}.jpg`).default;
  const textStyles = streamInverted
  ? { color: 'black' }
  : {
    color: 'white',
    backgroundColor: 'black',
    alginItems: 'center',
    textAlign: 'center',
    mixBlendMode: 'multiply',
    verticalAlign: 'middle',
  }

  const textComponent = (
    <Text
      nativeID="text"
      style={[styles.text, textStyles, { fontSize }]}
      numberOfLines={numberOfLines}
      ellipsizeMode="clip"
    >
      {numberOfLines === 1
        ? firstLineOfText
        : `${firstLineOfText}\n${secondLineOfText}`
      }
    </Text>
  )

  if (streamInverted) {
    return textComponent;
  }

  return (
    <View style={{
      backgroundImage: `url(${textBackgroundImage})`,
      width: width - (paddingHorizontalSize * 4),
    }}>
      {textComponent}
    </View>
  );
}

const styles = StyleSheet.create({
  // Moodular
  foreground: {
    position: 'relative',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  text: {
    fontFamily: 'Netflix Sans',
    fontWeight: 'bold',
    // fontWeight: '900',
    textAlign: 'center',
    letterSpacing: -0.7
  },
  textClipping: {
    // backgroundSize: '100% 100%',
    // backgroundClip: "text",
    // color: "transparent",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent"
  }
})