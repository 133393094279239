import React from 'react';
import _ from 'lodash';
import * as domtoimage from 'html-to-image';
import { View, Text, TextInput, Picker, Switch, StyleSheet } from 'react-native-web';

// Constants
import { Backgrounds, Canvas, StreamType, FontSize } from '../constants';

// Components
import Button from './Button';
import { randomizeStream } from '../utils';

export default function Sidebar({ state, actions }) {
  return (
    <View style={{flex: 1, overflow: 'scroll' }}>
      <View style={{flex: 1}}>
        <SidebarSection title="Canvas">
          <Picker
            style={styles.input}
            selectedValue={state.canvasId}
            onValueChange={canvasId => {
              const selectedCanvas = Canvas[canvasId];
              actions.setCanvasId(canvasId)
              actions.setFontSize(selectedCanvas.defaultFontSize)
            }}
          >
            {Object.entries(Canvas).map(([id, it]) => <Picker.Item key={id} label={it.name} value={id} />)}
          </Picker>
        </SidebarSection>
        <SidebarSection title="Content">
          <Text style={styles.label}>Number of Lines</Text>
          <Picker
            style={styles.input}
            selectedValue={state.numberOfLines.toString()}
            onValueChange={itemValue => actions.setNumberOfLines(parseInt(itemValue))}
          >
            <Picker.Item label="1" value="1" />
            <Picker.Item label="2" value="2" />
          </Picker>
          <Text style={styles.label}>First Line</Text>
          <TextInput style={styles.input} value={state.firstLineOfText} onChangeText={actions.setFirstLineOfText} placeholder="Write something" />
          {state.numberOfLines === 2 && (
            <View>
              <Text style={styles.label}>Second Line</Text>
              <TextInput style={styles.input} value={state.secondLineOfText} onChangeText={actions.setSecondLineOfText} placeholder="Write something" />
            </View>
          )}
          <Text style={styles.label}>Font Size</Text>
          <Picker
            style={styles.input}
            selectedValue={state.fontSize}
            onValueChange={(itemValue) => {
              actions.setFontSize(parseInt(itemValue))
            }}
          >
            {FontSize.map((fontSize) => (
              <Picker.Item key={fontSize.toString()} label={fontSize} value={fontSize} />
            ))}
          </Picker>
        </SidebarSection>
        <SidebarSection title="Design">
          <Text style={styles.label}>Stream Type</Text>
          <Picker
            style={styles.input}
            selectedValue={state.streamType}
            onValueChange={(itemValue) => {
              actions.setStreamType(itemValue)
              actions.setStreamColor(state.streamColor.replace(state.streamType, itemValue))
            }}
          >
            {Object.entries(StreamType).map(([type, id]) => (
              <Picker.Item key={id} label={type} value={id}/>
            ))}
          </Picker>
          <Text style={styles.label}>Stream Colors</Text>
          <Picker
            style={styles.input}
            selectedValue={state.streamColor}
            onValueChange={(itemValue) => {
              actions.setStreamColor(itemValue)
            }}
          >
            {Backgrounds.filter(it => it.id.includes(state.streamType)).map((it) => (
              <Picker.Item key={it.id} label={it.name} value={it.id} />
            ))}
          </Picker>
          <View style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginVertical: 10,
          }}>
            <Text style={styles.label}>Inverted</Text>
            <Switch
              activeThumbColor="#fff"
              activeTrackColor="#3272fd"
              trackColor="#c5c5c5"
              value={state.streamInverted}
              onValueChange={actions.setStreamInverted}
            />
          </View>
          <Button
            style={{ marginTop: 10 }}
            title="Auto Design™"
            backgroundColor="white"
            color="black"
            onPress={() => {
              const [streamTypeId, streamColorId] = randomizeStream();
              actions.setStreamType(streamTypeId)
              actions.setStreamColor(streamColorId)
              actions.setStreamInverted(!!_.random(0, 1))
            }}
          />
        </SidebarSection>
        <SidebarSection title="Export">
          <Button
            title="Export to JPG"
            onPress={async () => {
              const node = document.getElementById('moodular', {
                pixelRatio: 1
              });

              try {
                const dataUrl = await domtoimage.toJpeg(node);
                const link = document.createElement('a');
                link.download = createFileName(state, 'jpg');
                link.href = dataUrl;
                link.click();
              } catch(e) {
                console.error('oops, something went wrong!', e);
              }
            }}
          />
          <Button
            title="Export to PNG"
            onPress={async () => {
              const node = document.getElementById('moodular', {
                pixelRatio: 1
              });

              try {
                const dataUrl = await domtoimage.toPng(node);

                const link = document.createElement('a');
                link.download = createFileName(state, 'png');
                link.href = dataUrl;
                link.click();
              } catch(e) {
                console.error('oops, something went wrong!', e);
              }
            }}
          />
        </SidebarSection>
      </View>
    </View>
  )
}

function SidebarSection({ title, children }) {
  return (
    <View style={{
      marginTop: 20,
      paddingHorizontal: 20,
      borderBottomWidth: 1,
      borderBottomColor: '#e5e5e5'
    }}>
      <Text style={styles.sidebarSectionHeading}>{title}</Text>
      {children}
    </View>
  );
}

function createFileName({ canvasId, streamColor, streamInverted }, extension) {
  const isInverted = streamInverted ? '-inverted' : '';
  const canvas = Canvas[canvasId].id;
  const timestamp = +new Date();
  return `moodular-${timestamp}-${canvas}-${streamColor}${isInverted}.${extension}`;
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
  },

  header: {
    padding: 20,
    fontFamily: 'Netflix Sans',
    fontWeight: '900',
    fontSize: 32,
    borderBottomWidth: 1,
    borderColor: '#e5e5e5',
    backgroundColor: '#2c2c2c',
    color: 'white'
  },
  main: {
    flex: 1,
    flexDirection: 'row'
  },
  sidebar: {
    borderLeftWidth: 1,
    borderColor: '#e5e5e5',
    backgroundColor: 'white',
    overflow: 'scroll'
  },
  workArea: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e5e5e5'
  },

  // Editor
  sidebarSectionHeading: {
    marginBottom: 16,
    fontFamily: 'Netflix Sans',
    fontWeight: 'bold',
    fontSize: 16,
    color: '#333'
  },

  label: {
    marginBottom: 10,
    fontFamily: 'Netflix Sans',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#333'
  },

  input: {
    flex: 1,
    marginBottom: 20,
    padding: 10,
    paddingRight: 20,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: '#a0a0a0',
    fontSize: 14,
  },

  // Moodular
  foreground: {
    position: 'relative',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Netflix Sans',
    fontWeight: '900',
    textAlign: 'center'
  },
  textClipping: {
    // backgroundSize: '100% 100%',
    // backgroundClip: "text",
    // color: "transparent",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent"
  }
})