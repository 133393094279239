import _ from 'lodash';
import { Backgrounds, StreamType } from '../constants';


export function randomizeStream() {
  const defaultStreamType = Object.keys(StreamType)[_.random(0, Object.keys(StreamType).length - 1)];
  const defaultStreamColorId = Backgrounds[_.random(0, Backgrounds.length - 1)].id
    .replace(StreamType.Radiant, StreamType[defaultStreamType])
    .replace(StreamType.Ambient, StreamType[defaultStreamType]);

    return [StreamType[defaultStreamType], defaultStreamColorId]
}
