import React from 'react';
import { TouchableHighlight, Text, StyleSheet } from 'react-native-web';

export default function Button({ style = {}, title, onPress, backgroundColor = 'black', color = 'white'}) {
  return (
    <TouchableHighlight
      style={[styles.container, {
        backgroundColor
      }, style]}
      onPress={onPress}
    >
      <Text style={[styles.text, { color } ]}>
        {title}
      </Text>
    </TouchableHighlight>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'black',
    borderRadius: 4,
    marginBottom: 20,
    borderWidth: 1,
    padding: 10
  },
  text: {
    fontFamily: 'Netflix Sans',
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center'
  }
})